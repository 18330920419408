import React from "react";

const AboutTab = () => {
    // TODO: Read text from a JSON or text file.
    return (
        <div label="About" linkTo="/about">
            Bondsynth AI converts text to natural-sounding spoken-word audio.
        </div>
    );
};

export default AboutTab;
