import { Navigate } from "react-router-dom";
import React, { Component } from "react";
import PropTypes from "prop-types";
import validator from 'validator';

class WebTab extends Component {
    static propTypes = {
        apiEndpoint: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        webSynthesisLink: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentWebsiteText: "",
            selectedWebsite: "",
            urlErrorString: null,
            urlRedirect: null,
            webPageAudioSource: null,
        };
        this.selectedWebsiteRef = React.createRef();
    }

    componentDidMount() { }

    componentDidUpdate() { }

    handleChangeForWebsite(event) {
        let errorString = null;
        let selectedWebsite = "";
        const currentWebsiteText = event.target.value;
        if (currentWebsiteText.trim() !== "" && !validator.isURL(currentWebsiteText.trim())) {
            errorString = `ERROR: <br />'${currentWebsiteText}' <br />is not a valid URL`
            console.error(errorString + ` url was: '${currentWebsiteText}'`);
        } else {
            selectedWebsite = currentWebsiteText;
        }

        this.setState({
            currentWebsiteText: currentWebsiteText,
            selectedWebsite: selectedWebsite,
            urlErrorString: errorString,
        });
    }

    handleWebsiteSubmission() {
        const url = this.state.selectedWebsite.trim();
        if (url === "") {
            console.error("ERROR: you must select a website to synthesize!");
            return;
        }
        const requestBody = {
            url: url,
        };
        fetch(this.props.apiEndpoint + "/synthesisWebsite", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({ urlRedirect: result.download_url });
            })
            .catch((error) => {
                console.error(
                    `ERROR: Could not submit request for url: '${url}'\n` +
                    `Error was: '${error}'`);
            });
    }

    render() {
        const thisHandleChangeForWebsite =
            this.handleChangeForWebsite.bind(this);
        const thisHandleWebsiteSubmission =
            this.handleWebsiteSubmission.bind(this);

        let urlError = "";
        if (this.state.urlErrorString !== null) {
            urlError = (
                <div id="urlError" className="tab-content submission-error-message">
                    <p className="error-message" dangerouslySetInnerHTML={{ __html: this.state.urlErrorString }} />
                </div>);
        }
        let webPageAudioPlayer = "";
        if (this.state.webPageAudioSource != null) {
            const webPageBlobUrl = URL.createObjectURL(
                this.state.webPageAudioSource
            );
            webPageAudioPlayer = (
                <div className="web-tab-audio-player" label="WebPageAudioPlayer">
                    <audio controls src={webPageBlobUrl} type="audio/mpeg" />
                </div>
            );
        }

        return (
            <div id="webTabWrapper" className="tab-content">
                {this.state.urlRedirect && (
                    <Navigate to={this.state.urlRedirect} />
                )}
                <div className="web-tab-input-box" label="WebPageUpload">
                    <input
                        className="box-component box-input"
                        onChange={thisHandleChangeForWebsite}
                        onMouseOver={() => ({})}
                        placeholder="https://google.com"
                        value={this.state.currentWebsiteText}
                    />
                    <button className="box-component box-button" onClick={thisHandleWebsiteSubmission}>
                        Synthesize
                    </button>
                </div>
                {urlError}
                {webPageAudioPlayer}
            </div>
        );
    }
}

export default WebTab;
