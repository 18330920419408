import React from "react";

import Tabs from "./components/Tabs";
import { Outlet } from "react-router-dom";
import Logo from "./images/bondsynth-ai-dark--cropped.png";

const TabbedLayout = () => {
    return (
        <div label="TabbedLayoutRoot">
            <img className="page-title-logo" src={Logo} alt="Bondsynth AI logo" />
            <h1 className="page-title">Bondsynth AI</h1>
            <div>
                <Tabs
                    activeTab="Synthesis"
                    tabClassName="tab-list-item"
                    tabActiveClassName="tab-list-active"
                >
                    <div label="Synthesis" linkTo="/synthesis"></div>
                    {/* <div label="Books" linkTo="/books"></div> */}
                    <div label="About" linkTo="/about"></div>
                </Tabs>
            </div>
            <Outlet />
        </div>
    );
};

export default TabbedLayout;
