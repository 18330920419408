import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

class FileUploadTab extends Component {
    static propTypes = {
        apiEndpoint: PropTypes.string.isRequired,
        apiMethodName: PropTypes.string.isRequired,
        fileTypes: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
        ebookSynthesisLink: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            fileErrorString: null,
            fileTypes: props.fileTypes,
            isFileSelected: false,
            selectedFile: "",
            uploadBoxValue: "Select a file",

            urlRedirect: null,
        };
        this.hiddenFileInput = React.createRef();
    }

    componentDidMount() { }

    componentDidUpdate() { }

    handleEbookUploadClick() {
        this.hiddenFileInput.current.click();
    }

    handleChangeForFile(event) {
        const selectedFile = event.target.files[0];
        if (selectedFile === undefined) {
            return;
        }
        if (!this.state.fileTypes.some(fileType => selectedFile.name.endsWith(fileType))) {
            const errorString = `ERROR: Invalid file type: <br />'${selectedFile.name}' <br />Valid file types: '${this.state.fileTypes}'`
            console.error(errorString);
            this.setState({
                fileErrorString: errorString,
                isFileSelected: false,
                selectedFile: null,
                uploadBoxValue: "",
            });

            return
        }
        this.setState({
            fileErrorString: null,
            isFileSelected: true,
            selectedFile: selectedFile,
            uploadBoxValue:
                selectedFile.name.length > 80
                    ? selectedFile.name.substring(0, 80) + "..."
                    : selectedFile.name,
        });
    }

    handleFileSubmission() {
        if (this.state.selectedFile === "" || this.state.selectedFile === undefined) {
            console.error("ERROR: you must select a file to upload!");
            return;
        }
        const fileUploadUrl = this.props.apiEndpoint + this.props.apiMethodName;
        const formData = new FormData();
        formData.append("file", this.state.selectedFile);
        fetch(fileUploadUrl, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({ urlRedirect: result.download_url });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    render() {
        const thisHandleChangeForFile = this.handleChangeForFile.bind(this);
        const thisHandleEbookUploadClick =
            this.handleEbookUploadClick.bind(this);
        const thisHandleFileSubmission = this.handleFileSubmission.bind(this);

        let fileError = "";
        if (this.state.fileErrorString !== null) {
            fileError = (
                <div id="fileError" className="tab-content submission-error-message">
                    <p dangerouslySetInnerHTML={{ __html: this.state.fileErrorString }} />
                </div>);
        }
        let fileMetadata = "";
        if (this.state.isFileSelected) {
            fileMetadata = (<div id="fileMetadataWrapper" className="tab-content file-upload-metadata-wrapper">
                <FileMetadata
                    isFileSelected={this.state.isFileSelected}
                    selectedFile={this.state.selectedFile}
                ></FileMetadata>
            </div>);
        }

        return (
            <div id="ebookTabWrapper" className="tab-content">
                {this.state.urlRedirect && (
                    <Navigate to={this.state.urlRedirect} />
                )}
                <div id="ebookTabInnerWrapper" className="tab-content">
                    <div label="EbookUpload" className="file-upload-wrapper">
                        <input
                            type="file"
                            name="file"
                            ref={this.hiddenFileInput}
                            onChange={thisHandleChangeForFile}
                            style={{ display: "none" }}
                        />
                        <input
                            className="box-component box-input"
                            onChange={thisHandleChangeForFile}
                            onClick={thisHandleEbookUploadClick}
                            onMouseOver={() => ({})}
                            value={this.state.uploadBoxValue}
                        />
                        <button className="box-component box-button" onClick={thisHandleFileSubmission}>
                            Synthesize
                        </button>
                    </div>
                    {fileMetadata}
                    {fileError}
                </div>
            </div>
        );
    }
}

function FileMetadata(props) {
    const isFileSelected = props.isFileSelected;
    const selectedFile = props.selectedFile;
    const fileMetadataId = "fileMetadata";
    if (!isFileSelected) {
        return (
            <div className="file-upload-metadata" id={fileMetadataId}>
                <p></p>
            </div>
        );
    }
    return (
        <div className="file-upload-metadata" id={fileMetadataId}>
            <p className="file-upload-metadata-text">
                Filetype: {selectedFile.type}
                <br></br>
                Size in bytes: {selectedFile.size}
            </p>
        </div>
    );
}

export default FileUploadTab;
