import { Navigate } from "react-router-dom";
import React, { Component } from "react";
import PropTypes from "prop-types";

class TextTab extends Component {
    static propTypes = {
        apiEndpoint: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        textSynthesisLink: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentText: "",
            textRedirect: null,
            textPageAudioSource: null,
        };
    }

    componentDidMount() { }

    componentDidUpdate() { }

    handleChangeForText(event) {
        let errorString = null;
        const currentText = event.target.value;

        this.setState({
            currentText: currentText,
            textErrorString: errorString,
        });
    }

    handleTextSubmission() {
        const text = this.state.currentText.trim();
        if (text === "") {
            console.error("ERROR: you must input text to synthesize!");
            return;
        }
        const requestBody = {
            text: text,
        };
        fetch(this.props.apiEndpoint + "/synthesisText", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({ urlRedirect: result.download_url });
            })
            .catch((error) => {
                console.error(
                    `ERROR: Could not submit request for text: '${text}'\n` +
                    `Error was: '${error}'`);
            });
    }

    render() {
        const thisHandleChangeForText =
            this.handleChangeForText.bind(this);
        const thisHandleTextSubmission =
            this.handleTextSubmission.bind(this);

        let textAudioPlayer = "";
        if (this.state.textAudioSource != null) {
            const textBlobUrl = URL.createObjectURL(
                this.state.textAudioSource
            );
            textAudioPlayer = (
                <div className="text-tab-audio-player" label="TextAudioPlayer">
                    <audio controls src={textBlobUrl} type="audio/mpeg" />
                </div>
            );
        }

        return (
            <div id="textTabWrapper" className="tab-content">
                {this.state.urlRedirect && (
                    <Navigate to={this.state.urlRedirect} />
                )}
                <div className="text-tab-textarea-box" label="TextUpload">
                    <textarea
                        className="box-component box-textarea"
                        cols="80"
                        onChange={thisHandleChangeForText}
                        onMouseOver={() => ({})}
                        placeholder="Enter your text here....."
                        value={this.state.currentText}
                        wrap="hard"
                    />
                    <button className="box-component box-button" onClick={thisHandleTextSubmission}>
                        Synthesize
                    </button>
                </div>
                {textAudioPlayer}
            </div>
        );
    }
}

export default TextTab;
