import React from "react";
import PropTypes from "prop-types";

function Tab(props) {
    const onClick = () => {
        const { label, onClick } = props;
        onClick(label);
    };

    const { activeTab, label, className, activeClassName } = props;

    let curClassName = className;

    if (activeTab === label) {
        curClassName += " ";
        curClassName += activeClassName;
    }
    return (
        <li key={label} className={curClassName} onClick={onClick}>
            {label}
        </li>
    );
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    activeClassName: PropTypes.string.isRequired,
};

export default Tab;
