import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactPlayer from "react-player";

class SampleTab extends Component {
    static propTypes = {
        apiEndpoint: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        sampleSynthesisLink: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            sampleAudioUrl: null,
            sampleIntervalId: null,
            sampleSentences: null,
            selectedSentenceId: "sentence-1",
            selectedSentenceText: "",
        };
        this.sentenceSelectRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.sampleIntervalId != null) {
            clearInterval(this.state.sampleIntervalId);
            this.setState({
                sampleIntervalId: null,
            });
        }
        if (this.state.sampleSentences == null) {
            this.getSampleSentences()
                .then((sampleSentencesResult) => {
                    this.setState({ sampleSentences: sampleSentencesResult });
                    if (this.state.sampleAudioUrl == null) {
                        var synthesisId = this.getSynthesisIdForSampleSentence(
                            sampleSentencesResult,
                            this.state.selectedSentenceId
                        );

                        this.checkForSynthesisResult(synthesisId).then(
                            (synthesisCheckResult) => {
                                let synthesizedFileExists = JSON.parse(
                                    synthesisCheckResult.exists.toLowerCase()
                                );
                                if (!synthesizedFileExists) {
                                    this.makeSampleSynthesisRequest(
                                        this.state.selectedSentenceText,
                                        this.state.selectedSentenceId
                                    )
                                        .then((sampleAudioUrl) => {
                                            this.setState({
                                                sampleAudioUrl: sampleAudioUrl,
                                            });
                                        })
                                        .catch((error) => {
                                            console.error(
                                                "Error while requesting sample synthesis in componentDidMount: ",
                                                error
                                            );
                                        });
                                } else {
                                    this.setState({
                                        sampleAudioUrl:
                                            synthesisCheckResult.downloadUrl,
                                    });
                                }
                            }
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        "Error while getting samples in componentDidMount:",
                        error
                    );
                });
        }
    }

    componentDidUpdate() { }

    componentWillUnmount() {
        if (this.state.sampleIntervalId != null) {
            clearInterval(this.state.sampleIntervalId);
            this.setState({
                sampleIntervalId: null,
            });
        }
    }

    getSynthesisIdForSampleSentence(sampleSentencesResult, sampleSentenceId) {
        var synthesisId = null;
        for (const sampleSentenceResultIndex in sampleSentencesResult) {
            const sampleSentenceResult =
                sampleSentencesResult[sampleSentenceResultIndex];
            if (sampleSentenceResult[0] === sampleSentenceId) {
                synthesisId = sampleSentenceResult[2];
                break;
            }
        }
        return synthesisId;
    }

    async checkForSynthesisResult(synthesisId) {
        let fileCheckUrl =
            this.props.apiEndpoint +
            "/synthesisCheckForFile/text/" +
            synthesisId + ".mp3";
        let fileCheckResponse = await fetch(fileCheckUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        if (!fileCheckResponse.ok) {
            console.error(
                "Error code: " +
                fileCheckResponse.status +
                " Error status: " +
                fileCheckResponse.statusText +
                "\nGET request was to URL:" +
                fileCheckUrl
            );
        }
        let fileCheckResult = await fileCheckResponse.json();
        return fileCheckResult;
    }

    async getSampleSentences() {
        let sampleSentencesResponse = await fetch(
            this.props.apiEndpoint + "/synthesisSamples",
            {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }
        );
        if (!sampleSentencesResponse.ok) {
            console.error(
                "Error code: " +
                sampleSentencesResponse.status +
                " Error status: " +
                sampleSentencesResponse.statusText
            );
            return null;
        }
        let sampleSentences = await sampleSentencesResponse.json();
        sampleSentences.sort((a, b) => a[0].localeCompare(b[0]));
        return sampleSentences;
    }

    async handleChangeForText(event) {
        const targetText =
            event.target.options[event.target.selectedIndex].text;
        const sampleSentenceId = event.target.value;
        clearInterval(this.state.sampleIntervalId);
        let sampleAudioUrl = this.state.sampleAudioUrl;
        if (this.state.selectedSentenceId !== sampleSentenceId) {
            let synthesisId = this.getSynthesisIdForSampleSentence(
                this.state.sampleSentences,
                sampleSentenceId
            );
            let synthesisCheckResult = await this.checkForSynthesisResult(
                synthesisId
            );
            let synthesizedFileExists = JSON.parse(
                synthesisCheckResult.exists.toLowerCase()
            );
            if (synthesizedFileExists) {
                sampleAudioUrl = synthesisCheckResult.downloadUrl;
            } else {
                sampleAudioUrl = await this.makeSampleSynthesisRequest(
                    targetText,
                    sampleSentenceId
                );
            }
        }
        this.setState({
            sampleAudioUrl: sampleAudioUrl,
            selectedSentenceId: sampleSentenceId,
            selectedSentenceText: targetText,
        });
    }

    async handleTextSubmission() {
        let requestText = this.state.selectedSentenceText;
        let requestTextId = this.state.selectedSentenceId;
        if (this.state.selectedSentenceText === "") {
            requestText =
                this.sentenceSelectRef.current.options[
                    this.sentenceSelectRef.current.selectedIndex
                ].text;
            requestTextId =
                this.sentenceSelectRef.current.options[
                    this.sentenceSelectRef.current.selectedIndex
                ].value;
            this.setState({
                selectedSentenceId: requestTextId,
                selectedSentenceText: requestText,
            });
        }
        let sampleAudioUrl = await this.makeSampleSynthesisRequest(
            requestText,
            requestTextId
        );
        if (sampleAudioUrl != null) {
            this.setState({
                sampleAudioUrl: sampleAudioUrl,
            });
        }
    }

    async makeSampleSynthesisRequest(requestText, requestSentenceId) {
        // Set up audio context.
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        const requestBody = {
            sample_id: requestSentenceId,
            sample_text: requestText,
        };
        let uploadResponse = await fetch(
            this.props.apiEndpoint + "/synthesisSample",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
            }
        );
        if (!uploadResponse.ok) {
            console.error(
                "Error code: " +
                uploadResponse.status +
                " Error status: " +
                uploadResponse.statusText
            );
            console.error("Request was:", requestBody);
        }
        let uploadResult = await uploadResponse.json();
        let synthesisId = uploadResult.synthesis_id;
        let synthesisCheckResult = await this.checkForSynthesisResult(
            synthesisId
        );
        let synthesizedFileExists = JSON.parse(
            synthesisCheckResult.exists.toLowerCase()
        );
        var sampleAudioUrl = null;
        if (synthesizedFileExists) {
            sampleAudioUrl = synthesisCheckResult.downloadUrl;
        } else {
            if (this.state.sampleIntervalId != null) {
                clearInterval(this.state.sampleIntervalId);
            }
            const intervalId = setInterval(async () => {
                let synthesisCheckResult = await this.checkForSynthesisResult(
                    synthesisId
                );
                let synthesizedFileExists = JSON.parse(
                    synthesisCheckResult.exists.toLowerCase()
                );
                if (synthesizedFileExists) {
                    clearInterval(this.state.sampleIntervalId);
                    // TODO: Figure out how to fix issue where one sentence (with audio) is currently selected, but then a
                    //          a sentence that we're checking on finishes. Probably just clear the interval whenever a new
                    //          sentence is selected.....
                    this.setState({
                        sampleAudioUrl: synthesisCheckResult.downloadUrl,
                        sampleIntervalId: null,
                    });
                }
            }, 15000);
            this.setState({
                sampleIntervalId: intervalId,
            });
        }
        return sampleAudioUrl;
    }

    render() {
        const thisHandleChangeForText = this.handleChangeForText.bind(this);
        const thisHandleTextSubmission = this.handleTextSubmission.bind(this);
        var sampleReactSentenceAudioPlayer = (
            <div className="sample-audio-player" label="react-player-wrapper">
                <ReactPlayer
                    controls
                    className="react-player"
                    config={{ file: { forceAudio: true } }}
                    height="100%"
                    url={this.state.sampleAudioUrl}
                    width="100%"
                />
            </div>
        );

        var sentenceSelectOptions = "";
        if (this.state.sampleSentences != null) {
            sentenceSelectOptions = this.state.sampleSentences.map(
                (sampleSentenceTuple) => (
                    <option
                        key={sampleSentenceTuple[0]}
                        value={sampleSentenceTuple[0]}
                    >
                        {sampleSentenceTuple[1]}
                    </option>
                )
            );
        }
        return (
            <div id="sampleTabWrapper" className="tab-content sample-tab">
                <div className="sample-tab-select-box" label="SampleSentenceUpload">
                    <select
                        className="box-component box-input"
                        id="sentences"
                        name="sentences"
                        onChange={thisHandleChangeForText}
                        ref={this.sentenceSelectRef}
                        value={this.state.selectedSentenceId}
                    >
                        {sentenceSelectOptions}
                    </select>
                    <button className="box-component box-button" onClick={thisHandleTextSubmission}>
                        Synthesize
                    </button>
                </div>
                {sampleReactSentenceAudioPlayer}
            </div>
        );
    }
}

export default SampleTab;
