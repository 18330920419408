import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { Link } from "react-router-dom";

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
        activeTab: PropTypes.string,
        tabClassName: PropTypes.string.isRequired,
        tabActiveClassName: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        if (props.activeTab !== undefined) {
            this.state = { activeTab: props.activeTab };
        } else {
            this.state = {
                activeTab: this.props.children[0].props.label,
            };
        }
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: { children },
            state: { activeTab },
        } = this;

        return (
            <div className="tabs">
                <ol className="tab-list">
                    {children.map((child) => {
                        const { label, linkTo } = child.props;
                        return (
                            <Link key={label} to={linkTo}>
                                <Tab
                                    activeTab={activeTab}
                                    key={label}
                                    label={label}
                                    className={this.props.tabClassName}
                                    activeClassName={
                                        this.props.tabActiveClassName
                                    }
                                    onClick={(tab) => {
                                        onClickTabItem(tab);
                                    }}
                                    linkTo={linkTo}
                                />
                            </Link>
                        );
                    })}
                </ol>
            </div>
        );
    }
}

export default Tabs;
