import React from "react";
import { Route, Routes } from "react-router-dom";

import AboutTab from "./main_tabs/about_tab";
import SynthesisDownload from "./other_pages/synthesis_download";
import SynthesisTab from "./main_tabs/synthesis_tab";
import TabbedLayout from "./tabbed_layout";

const Main = (props) => {
    return (
        <Routes>
            <Route element={<TabbedLayout url={props.url} />}>
                <Route
                    path="/"
                    element={<SynthesisTab disableSynthesis={props.disableSynthesis} url={props.url} />}
                ></Route>
                <Route
                    path="/synthesis"
                    element={<SynthesisTab disableSynthesis={props.disableSynthesis} url={props.url} />}
                ></Route>
                <Route path="/about" element={<AboutTab />}></Route>
            </Route>
            <Route
                path="/synthesisDownload/:synthesis_type/:synthesis_id"
                element={<SynthesisDownload apiUrl={props.url} />}
            ></Route>
        </Routes>
    );
};

export default Main;
