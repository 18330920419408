import { Line } from "rc-progress";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import CopyIcon from "../images/copy-icon--white.png";
import Logo from "../images/bondsynth-ai-dark--cropped.png";

const SynthesisDownload = (props) => {
    let { synthesis_type, synthesis_id } = useParams();
    const fileProgressUrl =
        props.apiUrl +
        "/synthesisProgress/" +
        synthesis_type +
        "/" +
        synthesis_id;
    const getSynthesisTextUrl =
        props.apiUrl +
        "/getSynthesisText/" +
        synthesis_type +
        "/" +
        synthesis_id;

    let navigate = useNavigate();
    const [fileCheckIntervalId, setFileCheckIntervalId] = useState(null);
    const [fileDownloaded, setFileDownloaded] = useState(false);
    const [fileDownloadType, setFileDownloadType] = useState("mp3");
    const [fileDownloadUrl, setFileDownloadUrl] = useState("");
    const [synthesizedFileExists, setSynthesizedFileExists] = useState(false);

    // TODO: Change all these individual variables into a single (typed?) object.
    const [audioLengthSeconds, setAudioLengthSeconds] = useState(0.0);
    const [done, setDone] = useState(false);
    const [failed, setFailed] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState("");
    const [queued, setQueued] = useState(false);
    const [receivedTimestamp, setReceivedTimestamp] = useState("");
    const [sentencesSynthesized, setSentencesSynthesized] = useState(0);
    const [sentencesTotal, setSentencesTotal] = useState(0);
    const [synthesisStatus, setSynthesisStatus] = useState("");
    const [synthesisBeginTimestamp, setSynthesisBeginTimestamp] = useState("");
    const [synthesisTitle, setSynthesisTitle] = useState("");
    const [synthesisType, setSynthesisType] = useState("");
    const [synthesisWebsiteUrl, setSynthesisWebsiteUrl] = useState("");
    const [textOriginal, setTextOriginal] = useState("");
    const [textPreprocessed, setTextPreprocessed] = useState("");
    const [textSynthesized, setTextSynthesized] = useState("");
    const [timeElapsedSeconds, setTimeElapsedSeconds] = useState(0);
    const [timeQueuedSeconds, setTimeQueuedSeconds] = useState(0);
    const [userMessage, setUserMessage] = useState("");

    const curFileCheckIntervalId = useRef(fileCheckIntervalId);

    const fileCheckUrlSuffix = synthesis_type === "ebook" ? "--" + fileDownloadType : "." + fileDownloadType;
    const fileCheckUrl =
        props.apiUrl +
        "/synthesisCheckForFile/" +
        synthesis_type +
        "/" +
        synthesis_id + fileCheckUrlSuffix;

    const resetState = () => {
        if (fileCheckIntervalId !== null) {
            clearInterval(fileCheckIntervalId);
        }
        setFileCheckIntervalId(null);
        setFileDownloaded(false);
        setFileDownloadUrl("");
        setSynthesizedFileExists(false);
    };

    const handleOnChangeFileType = (event) => {
        setFileDownloadType(event.target.value);
    }

    const handleDownloadButtonClick = () => {
        if (synthesizedFileExists && !fileDownloaded) {
            setFileDownloaded(true);
            let fullFileDownloadUrl = fileDownloadUrl;
            if (synthesisType === "ebook") {
                fullFileDownloadUrl = fileDownloadUrl + "--" + fileDownloadType;
            } else {
                fullFileDownloadUrl = fileDownloadUrl + "." + fileDownloadType;
            }
            window.location.href = fullFileDownloadUrl;
        }
    };

    let checkForFile = async () => {
        let response = await fetch(fileCheckUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) {
            console.error("Status was:", response.status);
            console.error("Error was:", response.error);
            console.error("Request url was:", fileCheckUrl);
        }
        let result = await response.json();
        const synthesizedFileExistsResult = JSON.parse(
            result.exists.toLowerCase()
        );
        const fileDownloadUrlResult = result.downloadUrl;
        if (synthesizedFileExists || synthesizedFileExistsResult) {
            // fileDownloadUrlResult contains the full download path to whatever type
            // was requested, but the user may have changed the type, so we strip the
            // type. If one type has been uploaded then they all have (unless there's 
            // some sort of error or the user checks when the .wav file is done uploading
            // but before the .mp3 or .flac file has finished uploading. It's a very narrow
            // race condition, but it could happen.
            console.log("fileDownloadUrlResult: '%s'", fileDownloadUrlResult);
            // Split off the "--mp3", "--flac", or "--wav" that is tacked on for zip files.
            let fileDownloadUrlWithoutType = fileDownloadUrlResult.split("--")[0];
            // Split off the ".mp3", ".flac", or ".wav" file extensions.
            for (const fileExtension of [".mp3", ".flac", ".wav"]) {
                if (fileDownloadUrlWithoutType.endsWith(fileExtension)) {
                    fileDownloadUrlWithoutType = fileDownloadUrlWithoutType.slice(0, -fileExtension.length);
                    break;
                }
            }
            console.log("fileDownloadUrlWithoutType: '%s'", fileDownloadUrlWithoutType);
            setFileDownloadUrl(fileDownloadUrlWithoutType);
            setSynthesizedFileExists(true);
        }
        return synthesizedFileExists;
    };

    let checkForProgress = async () => {
        if (synthesizedFileExists && synthesisStatus === "Done") {
            return;
        }
        let response = await fetch(fileProgressUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) {
            console.error("Status was:", response.status);
            console.error("Error was:", response.error);
            console.error("Request url was:", fileProgressUrl);
        }
        let result = await response.json();
        const doneRes = JSON.parse(result.done);
        const failedRes = JSON.parse(result.failed);
        const inProgressRes = JSON.parse(result.in_progress);
        const queuedRes = JSON.parse(result.queued);
        console.log(
            "Title: " +
            result.synthesis_title +
            "\n" +
            "Synthesis type: " +
            result.synthesis_type +
            "\n" +
            "Synthesis URL: " +
            result.synthesis_website_url +
            "\n" +
            "File progress results:\n" +
            "Done?: " +
            doneRes +
            "\n" +
            "Failed?: " +
            failedRes +
            "\n" +
            "In progress?: " +
            inProgressRes +
            "\n" +
            "sentencesSynthesized / sentencesTotal: " +
            result.sentences_synthesized +
            " / " +
            result.sentences_total +
            "\n" +
            "Queued?: " +
            queuedRes +
            "\n" +
            "Last updated: " +
            result.last_update_timestamp +
            "\n" +
            "Recevied: " +
            result.received_timestamp +
            "\n" +
            "Synthesis begin: " +
            result.synthesis_begin_timestamp +
            "\n" +
            "Time elapsed (seconds): " +
            result.time_elapsed_seconds +
            "\n"
        );
        if (doneRes) {
            setDone(true);
            setFailed(false);
            setInProgress(false);
            setQueued(false);
            setSynthesisStatus("Done");
        } else if (failedRes) {
            setFailed(true);
            setInProgress(false);
            setQueued(false);
            setSynthesisStatus("Failed");
            clearInterval(fileCheckIntervalId);
        } else if (inProgressRes) {
            setInProgress(true);
            setQueued(false);
            setSynthesisStatus("In Progress");
        } else if (queuedRes) {
            setInProgress(false);
            setQueued(true);
            setSynthesisStatus("Queued");
        }
        setAudioLengthSeconds(result.audio_length_seconds);
        setLastUpdateTimestamp(result.last_update_timestamp);
        setReceivedTimestamp(result.received_timestamp);
        setSentencesSynthesized(result.sentences_synthesized);
        setSentencesTotal(result.sentences_total);
        setSynthesisBeginTimestamp(result.synthesis_begin_timestamp);
        setSynthesisTitle(result.synthesis_title !== "" ? result.synthesis_title : "Unnamed Synthesis");
        setSynthesisType(result.synthesis_type);
        setSynthesisWebsiteUrl(result.synthesis_website_url);
        setTimeElapsedSeconds(result.time_elapsed_seconds);
        setTimeQueuedSeconds(result.time_queued_seconds);
        setUserMessage(result.user_message);
    };

    let checkForTextBeingSynthesized = async () => {
        if (textSynthesized !== "" && textOriginal !== "") {
            return;
        }
        let response = await fetch(getSynthesisTextUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) {
            console.error("Status was:", response.status);
            console.error("Error was:", response.error);
            console.error("Request url was:", getSynthesisTextUrl);
        }
        let result = await response.json();
        const textOriginalRes = result.text_original;
        const textPreprocessedRes = result.text_preprocessed;
        const textSynthesizedRes = result.text_synthesized;
        setTextOriginal(textOriginalRes.replaceAll("\n", "<br />"));
        setTextPreprocessed(textPreprocessedRes.replaceAll("\n", "<br />"))
        setTextSynthesized(textSynthesizedRes.replaceAll("\n", "<br />"));
    };

    useEffect(() => {
        checkForFile();
        checkForProgress();
        checkForTextBeingSynthesized();
        return () => {
            resetState();
        };
    }, []);

    if (!synthesizedFileExists && fileCheckIntervalId == null) {
        var intervalId = setInterval(async () => {
            let synthesizedFileExists = await checkForFile();
            await checkForProgress();
            await checkForTextBeingSynthesized();
            if (synthesizedFileExists && synthesisStatus !== "Done") {
                await checkForProgress();
                await checkForTextBeingSynthesized();
            }
            if (
                curFileCheckIntervalId != null &&
                synthesizedFileExists && synthesisStatus === "Done"
            ) {
                clearInterval(curFileCheckIntervalId);
                setFileCheckIntervalId(null);
            }
        }, 10000);
        setFileCheckIntervalId(intervalId);
    } else if (synthesizedFileExists && fileCheckIntervalId != null && synthesisStatus === "Done") {
        clearInterval(fileCheckIntervalId);
        setFileCheckIntervalId(null);
    }

    let audioDurationText = "";
    let audioFileType = "";
    let audioPlayerAndSaveMessageSpace = "";
    let download = "";
    if (synthesizedFileExists) {
        download = (
            <div className="download-button-progress-bar-wrapper" label="Download">
                <button className="download-button" onClick={handleDownloadButtonClick}>
                    Download
                </button>
            </div>
        );
        audioFileType = (
            <div className="download-audio-file-type">
                File Type
                <div className="download-audio-file-type-radio-buttons">
                    <fieldset className="download-audio-file-type-fieldset">
                        <input type="radio" id="mp3" name="file-type" value="mp3" onChange={handleOnChangeFileType} defaultChecked />
                        <label for="mp3">mp3</label>
                        <input type="radio" id="flac" name="file-type" value="flac" onChange={handleOnChangeFileType} />
                        <label for="flac">flac</label>
                        <input type="radio" id="wav" name="file-type" value="wav" onChange={handleOnChangeFileType} />
                        <label for="wav">wav</label>
                    </fieldset>
                </div>
            </div>
        );
        if (audioLengthSeconds > 0) {
            audioDurationText += "Audio Duration: "
            if (audioLengthSeconds >= 3600) {
                audioDurationText += Math.floor(audioLengthSeconds / 3600) + " hours ";
            }
            if (audioLengthSeconds >= 60) {
                audioDurationText += Math.floor((audioLengthSeconds % 3600) / 60) + " minutes ";
            }
            // Only include seconds if we aren't measuring in hours.
            if (audioLengthSeconds < 3600 && audioLengthSeconds % 60 > 0) {
                audioDurationText += Math.floor((audioLengthSeconds % 60)) + " seconds";
            }
            audioDurationText = audioDurationText.trim()
        }
        // Only render the audio player if audio is < 1 hour.
        if (audioLengthSeconds < 3600) {
            audioPlayerAndSaveMessageSpace = (
                <div className="download-audio-player" label="react-player-wrapper">
                    <ReactPlayer
                        controls
                        className="react-player"
                        config={{ file: { forceAudio: true } }}
                        height="100%"
                        url={fileDownloadUrl + "." + fileDownloadType}
                        width="100%"
                    />
                </div>
            );
        }
    } else {
        let synthesisPercentComplete =
            sentencesSynthesized === 0
                ? 0
                : Math.min(Math.floor((sentencesSynthesized / sentencesTotal) * 100), 99);
        let synthesisPercentCompleteText =
            String(synthesisPercentComplete) + "% Synthesized";

        let synthesisProgressText;
        let synthesisProgressUserMessage
        if (failed) {
            const visibleUserErroMessage = userMessage === "" ? "Synthesis failed!" : userMessage;
            synthesisProgressText = "Synthesis Failed at " + synthesisPercentCompleteText;
            synthesisProgressUserMessage = (
                <span className="box-element download-user-message-text error-message">
                    ERROR:<br />
                    {visibleUserErroMessage}
                </span>);
        } else {
            synthesisProgressText = synthesisPercentCompleteText;
            synthesisProgressUserMessage = "";
        }
        download = (
            <div className="download-progress">
                <div className="download-button-progress-bar-wrapper" label="DownloadProgress">
                    <Line
                        className="download-progress-bar"
                        percent={synthesisPercentComplete}
                        strokeColor="#2a7abd"
                        strokeLinecap="square"
                        strokeWidth={3}
                        trailColor="#cccccc"
                        trailWidth={3}
                    />
                </div>
                <span className="box-element download-progress-bar-text">
                    {synthesisProgressText}
                </span>
                {synthesisProgressUserMessage}
            </div>
        );
        audioPlayerAndSaveMessageSpace = (
            <div className="download-synthesis-started-text">
                <h4>
                    Synthesis job started! <br />
                    You can save this URL, leave, come back, and everything will
                    keep running.
                </h4>
            </div>
        );
    }

    let copyUrl = () => {
        navigator.clipboard.writeText(synthesisWebsiteUrl);
    };
    let copyLinkButton = "";
    if (synthesisWebsiteUrl !== "") {
        copyLinkButton = (<img className="copy-link-image" onClick={copyUrl} src={CopyIcon} alt="copy website link." />);
        {/* <span className="copy-link-tooltip-text">Copy {synthesisWebsiteUrl}</span> */ }
    }

    return (
        <div label="DownloadPage" className="download-page">
            <div label="PageTitle">
                <img className="page-title-logo" src={Logo} alt="Bondsynth AI logo" />
                <h1 className="page-title">Bondsynth AI</h1>
            </div>
            <div className="download-back-button-container" label="BackButton">
                <button
                    className="download-back-button"
                    onClick={() => {
                        navigate("/");
                        resetState();
                    }}
                >
                    Back
                </button>
            </div>
            <div className="box download-box">
                <h3 className="download-text">{synthesisTitle}</h3>
                <div className="synthesis-website-url">
                    <a href={synthesisWebsiteUrl}>{synthesisWebsiteUrl}</a>
                    {copyLinkButton}
                </div>
                {download}
                {audioFileType}
                <div className="download-audio-duration-text">
                    {audioDurationText}
                </div>
            </div>
            {audioPlayerAndSaveMessageSpace}
            <div className="download-synthesis-status" label="SynthesisStatus">
                <h3 className="download-collapsible-header download-detailed-synthesis-status-text-header" onClick={collapsibleTextClick} type="button">
                    Detailed Synthesis Status
                </h3>
                <div className="download-detailed-synthesis-status">
                    <p className="download-collapsible-text download-detailed-synthesis-status-text-value">
                        Status: {String(synthesisStatus)} <br />
                        File exists: {String(synthesizedFileExists)} <br />
                        Synthesis id: {String(synthesis_id)} <br />
                        Synthesis type: {String(synthesisType)} <br />
                        Synthesis begin timestamp: {String(synthesisBeginTimestamp)}
                        <br />
                        Last Updated: {String(lastUpdateTimestamp)}
                        <br />
                        Time elapsed (minutes):{" "}
                        {String((timeElapsedSeconds / 60.0).toFixed(1))}
                        <br />
                        Time elapsed (hours): {String((timeElapsedSeconds / 3600).toFixed(1))}
                        <br />
                        Time queued (seconds): {String(timeQueuedSeconds.toFixed(1))} <br />
                        Time queued (minutes): {String((timeQueuedSeconds / 60.0).toFixed(1))} <br />
                        Synthesis progress: {String(sentencesSynthesized)} /{" "}
                        {String(sentencesTotal)} sentences
                        <br />
                    </p>
                </div>
            </div>
            <div className="download-synthesized-text" label="OriginalText">
                <h3 className="download-collapsible-header original-text-header" onClick={collapsibleTextClick} type="button">Original Text</h3>
                <p className="download-collapsible-text original-text-value collapsible-retrieved-text" dangerouslySetInnerHTML={{ __html: textOriginal }} />
            </div>
            <div className="download-synthesized-text preprocess-text" label="PreprocessText">
                <h3 className="download-collapsible-header preprocess-text-header" onClick={collapsibleTextClick} type="button">Preprocessed Text</h3>
                <p className="download-collapsible-text preprocess-text-value collapsible-retrieved-text" dangerouslySetInnerHTML={{ __html: textPreprocessed }} />
            </div>
            <div className="download-synthesized-text synthesis-text" label="SynthesisText">
                <h3 className="download-collapsible-header synthesis-text-header" onClick={collapsibleTextClick} type="button">Synthesized Text</h3>
                <p className="download-collapsible-text synthesis-text-value collapsible-retrieved-text" dangerouslySetInnerHTML={{ __html: textSynthesized }} />
            </div>
        </div>
    );
};

function collapsibleTextClick(event) {
    event.target.classList.toggle("toggle-active");
    let targetTextClassName = Array.from(event.target.classList.values())
        .filter(className => String(className).endsWith("text-header"))[0]
        .replaceAll("text-header", "text-value");
    let content = document.getElementsByClassName(targetTextClassName)[0];
    if (content.style.display === "block") {
        content.style.display = "none";
    } else {
        content.style.display = "block";
    }
}

export default SynthesisDownload;
