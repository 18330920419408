import React from "react";
import PropTypes from "prop-types";

import Explainer from "../components/Explainer";
import SynthesisBox from "../main_box/synthesis_box";

const SynthesisTab = (props) => {
    const url = props.url;

    const explainerStyle = {
        margin: "0 auto",
        textAlign: "center",
        width: "min(45em, 75%)",
    };
    return (
        <div className="synthesis-tab" label="Synthesis">
            <SynthesisBox
                apiEndpoint={url}
                disableSynthesis={props.disableSynthesis}
                ebookSynthesisLink="/synthesis#ebook"
                sampleSynthesisLink="/synthesis#sample"
                textSynthesisLink="/synthesis#text"
                webSynthesisLink="/synthesis#web"
            />
            <Explainer disableSynthesis={props.disableSynthesis} style={explainerStyle} />
        </div>
    );
};

SynthesisTab.propTypes = {
    url: PropTypes.string.isRequired,
};

export default SynthesisTab;
