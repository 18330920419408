import React from "react";

import "./App.css";
import Main from "./main.js";

function App() {
    const url = process.env.REACT_APP_URL;
    const disableSynthesis = process.env.REACT_APP_DISABLE_SYNTHESIS;
    return (
        <div label="AppRoot" className="App">
            <Main disableSynthesis={disableSynthesis} url={url} />
        </div>
    );
}

export default App;
